const statuses = [{
        name: "全部",
        value: "0"
    },
    {
        name: "上线",
        value: 1
    },
    {
        name: "下线",
        value: 2
    },
];
const status2 = [{
        name: "上线",
        value: 1
    },
    {
        name: "下线",
        value: 2
    },
];
const jobstatuses = [{
        name: "离职-随时到岗",
        value: 1
    },
    {
        name: "在职-月内到岗",
        value: 2
    },
    {
        name: "在职-考虑机会",
        value: 3
    },
    {
        name: "在职-暂不考虑",
        value: 4
    },
];


export {
    statuses,status2,jobstatuses
};