<template>
  <div>
    <div class="tabs-box">
      <el-tabs
        type="border-card"
        v-model="selectedTab"
        @tab-click="handle_tabclick"
      >
        <el-tab-pane label="用户详细" name="first">
          <div class="row center"></div>
          <div class="block-box">
            <el-form ref="form1" :model="cuttData" :rules="rules1">
              <el-form-item label="用户名" label-width="100px" prop="username">
                <el-input v-model="cuttData.username"></el-input>
              </el-form-item>
              <el-form-item
                label="密码"
                label-width="100px"
                prop="password"
                v-if="!cuttData.id"
              >
                <el-input
                  type="password"
                  v-model="cuttData.password"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="重复密码"
                label-width="100px"
                prop="verifyPassword"
                v-if="!cuttData.id"
              >
                <el-input
                  type="password"
                  v-model="cuttData.verifyPassword"
                ></el-input>
              </el-form-item>
              <el-form-item label="用户姓名" label-width="100px" prop="name">
                <el-input v-model="cuttData.name"></el-input>
              </el-form-item>
              <el-form-item label="手机号" label-width="100px" prop="mobile">
                <el-input v-model="cuttData.mobile"></el-input>
              </el-form-item>
              <el-form-item label="微信号" label-width="100px" prop="wechatid">
                <el-input v-model="cuttData.wechatid"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" label-width="100px" prop="email">
                <el-input v-model="cuttData.email"></el-input>
              </el-form-item>
              <el-form-item label="用户状态" label-width="100px" prop="status">
                <el-select v-model="cuttData.status" style="margin-right: 5px">
                  <el-option
                    v-for="item in status2"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-button
              type="primary"
              @click="handle_validate1('form1')"
              :disabled="lock"
              >确 定</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="用户简历" name="second" v-if="!!cuttData.id">
          <div class="row center"></div>
          <div class="block-box">
            <el-form ref="form2" :model="userResume" :rules="rules2">
              <el-form-item
                label="个人优势"
                label-width="100px"
                prop="advantage"
              >
                <el-input
                  type="textarea"
                  v-model="userResume.advantage"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="求职状态"
                label-width="100px"
                prop="jobstatus"
              >
                <el-select
                  v-model="userResume.jobstatus"
                  style="margin-right: 5px"
                >
                  <el-option
                    v-for="item in jobstatuses"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="简历状态" label-width="100px" prop="status">
                <el-select
                  v-model="userResume.status"
                  style="margin-right: 5px"
                >
                  <el-option
                    v-for="item in status2"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-button
              type="primary"
              @click="handle_validate2('form2')"
              :disabled="lock"
              >确 定</el-button
            >
          </div>
        </el-tab-pane>
        <el-tab-pane label="工作期望" name="third" v-if="!!cuttData.id">
          <div class="row">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="handle_addexpection()"
              >添加</el-button
            >
          </div>
          <div>
            <el-table
              :data="userExpectionList"
              v-loading="showLoading"
              element-loading-text="拼命加载中"
              border
              width="width:100%; height:50%"
            >
              <el-table-column
                type="index"
                label="顺序"
                width="50"
              ></el-table-column>
              <el-table-column label="职位">
                <template #default="scope">
                  {{ scope.row.category }} {{ scope.row.subcategory }}
                </template>
              </el-table-column>
              <el-table-column label="省市县" width="200" v>
                <template #default="scope">
                  {{ scope.row.province }} {{ scope.row.city }}
                  {{ scope.row.area }}
                </template>
              </el-table-column>
              <el-table-column
                label="工资"
                width="200"
                :formatter="formatterSalary"
              >
              </el-table-column>
              <el-table-column label="操作" conter width="180">
                <template #default="scope">
                  <el-button
                    type="text"
                    icon="el-icon-edit-outline"
                    @click="handle_editexpection(scope.row)"
                    >编辑
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-delete-outline"
                    @click="handle_deleteexpection(scope.row)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="经验" name="four" v-if="!!cuttData.id">
          <div class="row">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="handle_addexperience()"
              >添加</el-button
            >
          </div>
          <div>
            <el-table
              :data="userExperienceList"
              v-loading="showLoading"
              element-loading-text="拼命加载中"
              border
              width="width:100%; height:50%"
            >
              <el-table-column
                type="index"
                label="顺序"
                width="50"
              ></el-table-column>
              <el-table-column label="公司名称" prop="companyname" width="">
              </el-table-column>
              <el-table-column label="职位" prop="position" width="300">
              </el-table-column>
              <el-table-column
                label="日期"
                width="200"
                :formatter="formatterExperienceDate"
              >
              </el-table-column>
              <el-table-column label="操作" conter width="180">
                <template #default="scope">
                  <el-button
                    type="text"
                    icon="el-icon-edit-outline"
                    @click="handle_editexperience(scope.row)"
                    >编辑
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    @click="handle_deleteexperience(scope.row)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="教育经历" name="five" v-if="!!cuttData.id">
          <div class="row">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="handle_addeducation()"
              >添加</el-button
            >
          </div>
          <div>
            <el-table
              :data="userEducationList"
              v-loading="showLoading"
              element-loading-text="拼命加载中"
              border
              width="width:100%; height:50%"
            >
              <el-table-column
                type="index"
                label="顺序"
                width="50"
              ></el-table-column>
              <el-table-column label="学校名称" prop="schoolname" width="">
              </el-table-column>
              <el-table-column label="专业" prop="major" width="300">
              </el-table-column>
              <el-table-column label="学历" width="100" prop="backgroundname">
              </el-table-column>
              <el-table-column
                label="日期"
                width="200"
                :formatter="formatterExperienceDate"
              >
              </el-table-column>
              <el-table-column label="操作" conter width="180">
                <template #default="scope">
                  <el-button
                    type="text"
                    icon="el-icon-edit-outline"
                    @click="handle_editeeducation(scope.row)"
                    >编辑
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    @click="handle_deleteeducation(scope.row)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="证书" name="six" v-if="!!cuttData.id">
          <div class="row">
            <el-button
              type="primary"
              icon="el-icon-plus"
              @click="handle_addcertification()"
              >添加</el-button
            >
          </div>
          <div>
            <el-table
              :data="userCertificationList"
              v-loading="showLoading"
              element-loading-text="拼命加载中"
              border
              width="width:100%; height:50%"
            >
              <el-table-column
                type="index"
                label="顺序"
                width="50"
              ></el-table-column>
              <el-table-column label="名称" prop="name" width="">
              </el-table-column>
              <el-table-column label="证书图片" width="300">
                <template #default="scope">
                  <el-image :src="computeImageUrl(scope.row.url)"></el-image>
                </template>
              </el-table-column>
              <el-table-column label="操作" conter width="180">
                <template #default="scope">
                  <el-button
                    type="text"
                    icon="el-icon-delete"
                    @click="handle_deletecertification(scope.row)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!--user expection-->
    <el-dialog
      title="用户期望"
      :close-on-click-modal="false"
      width="600px"
      v-model="dlgShowExpection"
    >
      <el-form ref="form3" :model="userExpection" :rules="rules3">
        <el-form-item label="类型" label-width="100px" prop="typeid">
          <el-select v-model="userExpection.typeid" style="margin-right: 5px">
            <el-option
              v-for="(item, index) in jobTypeList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位父分类" label-width="100px" prop="categoryid">
          <el-select
            v-model="userExpection.categoryid"
            style="margin-right: 5px"
            @change="handle_expectionchangecategory"
          >
            <el-option
              v-for="(item, index) in categoryList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位分类" label-width="100px" prop="categoryid">
          <el-select
            v-model="userExpection.subcategoryid"
            style="margin-right: 5px"
          >
            <el-option
              v-for="(item, index) in subCategoryList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="省" label-width="100px" prop="provinceid">
          <el-select
            v-model="userExpection.provinceid"
            style="margin-right: 5px"
            @change="handle_expectionchangeprovince"
          >
            <el-option
              v-for="(item, index) in provinces"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市" label-width="100px" prop="cityid">
          <el-select
            v-model="userExpection.cityid"
            style="margin-right: 5px"
            @change="handle_expectionchangecity"
          >
            <el-option
              v-for="(item, index) in selectCityList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县(区)" label-width="100px" prop="areaid">
          <el-select v-model="userExpection.areaid" style="margin-right: 5px">
            <el-option
              v-for="(item, index) in selectAreaList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="最低工资"
          label-width="100px"
          prop="minsalaryValue"
        >
          <el-select
            v-model="userExpection.minsalaryValue"
            style="margin-right: 5px"
            @change="handle_changeexpectionminsalary"
          >
            <el-option
              v-for="(item, index) in minsalaryList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="最高工资"
          label-width="100px"
          prop="maxsalaryValue"
          v-show="
            userExpection.minsalary != 0 &&
            userExpection.minsalaryValue != '面议'
          "
        >
          <el-select
            v-model="userExpection.maxsalaryValue"
            style="margin-right: 5px"
            @change="handle_changeexpectionmaxsalary"
          >
            <el-option
              v-for="(item, index) in maxsalaryList"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgShowExpection = false">取 消</el-button>
        <el-button type="primary" @click="handle_validate3('form3')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--user experience-->
    <el-dialog
      title="用户经验"
      :close-on-click-modal="false"
      width="600px"
      v-model="dlgShowExperience"
    >
      <el-form ref="form4" :model="userExperience" :rules="rules4">
        <el-form-item label="公司名称" label-width="100px" prop="target">
          <el-input
            v-model="userExperience.companyname"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位" label-width="100px" prop="position">
          <el-input v-model="userExperience.position"> </el-input>
        </el-form-item>
        <el-form-item label="开始日期" label-width="100px" prop="fromdate">
          <el-date-picker
            v-model="userExperience.fromdate"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" label-width="100px" prop="todate">
          <el-date-picker
            v-model="userExperience.todate"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="详细" label-width="100px" prop="description">
          <el-input
            type="textarea"
            :rows="5"
            maxlength="500"
            v-model="userExperience.description"
            placeholder="请输入详细"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgShowExperience = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handle_validate4('form4')"
          :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--user education-->
    <el-dialog
      title="用户学历"
      :close-on-click-modal="false"
      width="600px"
      v-model="dlgShowEducation"
    >
      <el-form ref="form5" :model="userEducation" :rules="rules5">
        <el-form-item label="学校名称" label-width="100px" prop="schoolname">
          <el-input
            v-model="userEducation.schoolname"
            placeholder="请输入学校名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="专业" label-width="100px" prop="major">
          <el-input v-model="userEducation.major"> </el-input>
        </el-form-item>
        <el-form-item label="学历" label-width="100px" prop="backgroundid">
          <el-select
            v-model="userEducation.backgroundid"
            style="margin-right: 5px"
          >
            <el-option
              v-for="item in backgrounds"
              :key="item"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始日期" label-width="100px" prop="fromdate">
          <el-date-picker
            v-model="userEducation.fromdate"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期" label-width="100px" prop="todate">
          <el-date-picker
            v-model="userEducation.todate"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="详细" label-width="100px" prop="description">
          <el-input
            type="textarea"
            :rows="5"
            maxlength="500"
            v-model="userEducation.description"
            placeholder="请输入详细"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgShowEducation = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handle_validate5('form5')"
          :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!--certification-->
    <el-dialog
      title="证书"
      :close-on-click-modal="false"
      width="600px"
      v-model="dlgShowCertification"
    >
      <el-form ref="form6" :model="userCertifiction" :rules="rules6">
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input
            v-model="userCertification.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="证书图片" label-width="100px">
          <el-upload
            class="upload-demo"
            :action="actionUrl"
            accept="image/jpeg,image/gif,image/png"
            :headers="uploadHeaders"
            :on-success="successUploadImage"
            :limit="1"
          >
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dlgShowCertification = false">取 消</el-button>
        <el-button type="primary" @click="handle_save6()" :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import USER from "../../api/lianying/api_user";
import CATEGORY from "../../api/lianying/api_jobcategory";
import moment from "moment";
import { jobstatuses, status2 } from "../../common/status.js";
import provinces from "../../common/province.js";
import cities from "../../common/city.js";
import areas from "../../common/area.js";
import jobTypeList from "../../common/jobtype.js";
import educationList from "../../common/backgrounds.js";
import * as API from "../../utils/request";

export default {
  name: "followEdit",
  data() {
    return {
      actionUrl: "",
      showLoading: false,
      categoryList: [],
      subCategoryList: [],
      provinces: provinces,
      cities: cities,
      areas: areas,
      selectCityList: [],
      selectAreaList: [],
      jobTypeList: jobTypeList,

      minsalaryList: [],
      maxsalaryList: [],

      selectedTab: "first",

      jobstatuses: jobstatuses,
      status2: status2,

      lock: false,

      //form1
      //user info
      cuttData: {
        id: undefined,
        name: "",
        username: "",
        password: "",
        verifyPassword: "",
        mobile: "",
        wechatid: "",
        status: undefined,
      },
      rules1: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        // password: [
        //   { required: true, message: "请输入密码", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 50,
        //     message: "长度在 1 到 50 个字符",
        //     trigger: "blur",
        //   },
        // ],
        // verifyPassword: [
        //   { required: true, message: "请输入重复密码", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 50,
        //     message: "长度在 1 到 50 个字符",
        //     trigger: "blur",
        //   },
        // ],
        // mobile: [
        //   { required: true, message: "请输入手机号", trigger: "blur" },
        //   {
        //     min: 11,
        //     max: 11,
        //     message: "长度为 11 个数字",
        //     trigger: "blur",
        //   },
        // ],
        // wechatid: [
        //   { required: true, message: "请输入微信号", trigger: "blur" },
        //   {
        //     min: 5,
        //     max: 50,
        //     message: "长度在 5 到 50 个字符",
        //     trigger: "blur",
        //   },
        // ],
        
      },

      //form2
      userResume: {
        id: undefined,
        userid: undefined,
        advance: "",
        status: undefined,
        resumestatus: undefined,
        jobstatus: undefined,
      },
      rules2: {
        // advance: [
        //   { required: true, message: "请输入个人优势", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 200,
        //     message: "长度在 1 到 200 个字符",
        //     trigger: "blur",
        //   },
        // ],
        status: [
          { required: true, message: "请选择简历状态", trigger: "blur" },
        ],
        jobstatus: [
          { required: true, message: "请选择求职状态", trigger: "blur" },
        ],
      },

      //form3
      dlgShowExpection: false,
      userExpection: {
        typeid: 1,
        province: "",
        provinceid: "",
        city: "",
        cityid: "",
        area: "",
        areaid: "",
        categoryid: undefined,
        category: "",
        subcategoryid: undefined,
        subcategory: "",
        minsalary: 0,
        maxsalary: 0,
        minsalaryValue: "面议",
        maxsalaryValue: "面议",
      },

      userExpectionList: [],
      rules3: {
        typeid: [
          { required: true, message: "请输入职位类型", trigger: "blur" },
        ],
        categoryid: [
          { required: true, message: "请输入上级职位", trigger: "blur" },
        ],
        // subcategoryid: [
        //   { required: true, message: "请输入职位", trigger: "blur" },
        // ],
        provinceid: [{ required: true, message: "请输入省", trigger: "blur" }],
        // cityid: [{ required: true, message: "请输入市", trigger: "blur" }],
        // areaid: [{ required: true, message: "请输入县(区)", trigger: "blur" }],
        minsalaryValue: [
          { required: true, message: "请输入最低工资", trigger: "blur" },
        ],
      },

      //form4
      dlgShowExperience: false,
      userExperience: {
        userid: "",
        companyname: "",
        fromdate: "",
        todate: "",
        position: "",
        description: "",
      },
      userExperienceList: [],
      rules4: {
        companyname: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        position: [
          { required: true, message: "请输入职位名", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        description: [
          { required: true, message: "请输入详细", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        fromdate: [
          { required: true, message: "请输入开始日期", trigger: "blur" },
        ],
        todate: [
          { required: true, message: "请输入结束日期", trigger: "blur" },
        ],
      },

      //education
      backgrounds: educationList,
      dlgShowEducation: false,
      userEducation: {
        userid: "",
        schoolname: "",
        fromdate: "",
        todate: "",
        major: "",
        backgroundid: undefined,
        description: "",
      },
      userEducationList: [],
      rules4: {
        schoolname: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        major: [
          { required: true, message: "请输入专业名", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        backgroundid: [
          { required: true, message: "请输入学历", trigger: "blur" },
        ],
        description: [
          { required: true, message: "请输入详细", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        fromdate: [
          { required: true, message: "请输入开始日期", trigger: "blur" },
        ],
        todate: [
          { required: true, message: "请输入结束日期", trigger: "blur" },
        ],
      },
      //certification
      uploadCertificationUrl: API.getUrl + "/user/uploadImg",
      userCertificationList: [],
      dlgShowCertification: false,
      uploadHeaders: {},
      userCertification: {
        userid: "",
        name: "",
        uploadfileid: undefined,
      },
      rules6: {
        name: [
          { required: true, message: "请输入证书名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    userExpection: function (newValue, oldValue) {},
  },
  mounted() {
    this.init();
    let token = localStorage.getItem("access-token");
    this.uploadHeaders.Authorization = token;
    console.log("upload header " + JSON.stringify(this.uploadHeaders));
    this.actionUrl = API.getUrl() + "/uploadfile/uploadimg";
  },
  computed: {
    computeImageUrl: function () {
      return function (imageUrl) {
        console.log("image url " + JSON.stringify(imageUrl));
        if (!imageUrl) {
          return "";
        }
        if (imageUrl.indexOf("http") == 0) {
          return imageUrl;
        } else {
          let url = API.getImageUrl() + "" + imageUrl;
          console.log("url " + url);
          return url;
        }
      };
    },
    computeUploadImageUrl: function () {
      return API.getUrl() + "/" + "user/uploadImg";
    },
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
      console.log("statuses " + JSON.stringify(this.statuses));
    },
    initPageData() {
      let id = this.$route.query.id;
      console.log("id " + id);
      if (id) {
        USER.get({
          id: id,
        }).then((res) => {
          console.log("init user " + JSON.stringify(res));
          this.showLoading = false;
          if (1 == res.status) {
            this.cuttData = res.data;
            this.userResume = res.data.userResume;
            if (!this.userResume) {
              this.userResume = {};
              this.userResume.userid = id;
            }
            this.userExpectionList = res.data.expectionList;
            this.userExperienceList = res.data.experienceList;
            this.userEducationList = res.data.educationList;
            this.userCertificationList = res.data.certificationList;
          } else {
            this.$message.error(res.message);
            //this.initAddData();
          }
        });
      }
      this.initCategory();
      this.initUserExpectionMinsalary();
    },
    initCategory() {
      CATEGORY.listRoot().then((res) => {
        console.log("root category list " + JSON.stringify(res));
        if (res.status == 1) {
          this.categoryList = res.data;
        }
      });
      if (this.userExpection.categoryid) {
        CATEGORY.listByParentId({
          parentid: this.userExpection.categoryid,
        }).then((res) => {
          if (res.status == 1) {
            this.subCategoryList = res.data;
          }
        });
      }
    },
    initUserExpectionMinsalary: function () {
      this.minsalaryList = ["面议"];
      for (let i = 1; i < 351; ) {
        let salary = i + "K";
        this.minsalaryList.push(salary);
        if (i < 30) {
          i++;
        } else if (i < 100) {
          i = i + 5;
        } else {
          i = i + 10;
        }
      }
    },
    initUserExpectionMaxsalary: function () {
      let minsalary = this.userExpection.minsalary;
      let minsalaryValue = this.userExpection.minsalary + "K";
      let minsalaryIndex = 0;
      if (minsalary == 0 || minsalaryValue == "面议") {
        return;
      }
      minsalaryIndex = this.minsalaryList.findIndex((item, index) => {
        return item == minsalaryValue;
      });

      this.maxsalaryList = [];
      this.maxsalaryList = this.minsalaryList.splice(minsalaryIndex + 1);
    },
    handle_expectionchangecategory(e) {
      console.log("change category " + JSON.stringify(e));
      if (e) {
        this.userExpection.subcategoryid = undefined;
        this.userExpection.subcategory = undefined;
        this.initCategory();
      }
    },
    handle_expectionchangeprovince(e) {
      console.log("change province " + JSON.stringify(e));
      this.selectCityList = [];
      if (e) {
        this.selectAreaList = [];
        this.selectCityList = [];

        let idx = this.provinces.findIndex((item, index) => {
          return item.value == e;
        });

        console.log("province in index " + idx);
        if (idx > -1) {
          this.selectCityList = this.cities[idx];
          this.userExpection.province = this.provinces[idx].label;
          this.userExpection.provinceid = this.provinces[idx].value;
        }
        console.log("city list " + JSON.stringify(this.selectCityList));
      }
    },
    handle_expectionchangecity(e) {
      console.log("change city " + JSON.stringify(e));
      this.selectAreaList = [];
      console.log("select city " + JSON.stringify(this.selectCityList));
      if (e) {
        let idx1 = this.provinces.findIndex((item, index) => {
          return item.value == this.userExpection.provinceid;
        });
        let idx2 = this.selectCityList.findIndex((item, index) => {
          return item.value == e;
        });
        console.log("city index " + idx1 + ", " + idx2);
        if (idx1 > -1 && idx2 > -1) {
          this.selectAreaList = this.areas[idx1][idx2];
          this.userExpection.city = this.selectCityList[idx2].label;
          this.userExpection.cityid = this.selectCityList[idx2].value;
        }
      }
      console.log("select area " + JSON.stringify(this.selectAreaList));
    },
    //user info save
    handle_tabclick(tab, event) {
      console.log("select tab " + this.selectedTab);
      if (this.selectedTab === "second") {
      }
    },
    handle_validate(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save();
        } else {
          return false;
        }
      });
    },
    handle_validate1(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save1();
        } else {
          return false;
        }
      });
    },
    async handle_save1() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      USER.save(this.cuttData).then((res) => {
        this.digShow = this.lock = false;
        if (res.status == 1) {
          if (!this.cuttData.id) {
            this.cuttData = res.data;
            this.userResume = {};
            this.userResume.userid = this.cuttData.id;
          }
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    async handle_save() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      USER.save(this.cuttData).then(this.res_handle);
    },
    //结果请求设置
    res_handle(res) {
      console.log("res " + JSON.stringify(res));
      this.digShow = this.lock = false;
      if (1 === res.status) {
        this.$message.success("操作成功！");
        //this.init();
      } else {
        this.$message.error(res.message);
      }
    },
    //form2
    handle_validate2(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save2();
        } else {
          return false;
        }
      });
    },
    async handle_save2() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      this.userResume.resumestatus = this.userResume.status;
      USER.saveUserResume(this.userResume).then(this.res_handle);
    },
    //form 3 expect
    handle_changeexpectionminsalary(e) {
      console.log("expection minsalary " + e);
      //this.userExpection.minsalaryValue = e;
      this.userExpection.minsalary = this.minsalaryList.findIndex(
        (item, idx) => {
          return item == e;
        }
      );
      this.initUserExpectionMaxsalary();
    },
    handle_changeexpectionmaxsalary(e) {
      console.log("max salary " + JSON.stringify(e));
      if (e) {
        let value = e.replace("K", "");
        this.userExpection.maxsalary = parseInt(value);
      }
    },
    handle_editexpection(row) {
      console.log("edit expection " + JSON.stringify(row));
      if (row) {
        this.userExpection = row;

        if (this.userExpection.minsalary) {
          this.userExpection.minsalaryValue =
            this.userExpection.minsalary + "K";
        } else {
          this.userExpection.minsalaryValue = "面议";
        }
        if (this.userExpection.maxsalary) {
          this.userExpection.maxsalaryValue =
            this.userExpection.maxsalary + "K";
        } else {
          this.userExpection.maxsalaryValue = "面议";
        }
      } else {
        this.userExpection = {};
        this.userExpection.minsalary = 0;
        this.userExpection.minsalaryValue = "面议";
        this.userExpection.maxsalary = 0;
      }
      this.dlgShowExpection = true;
      this.initUserExpectionMaxsalary();
      this.initCategory();
      this.handle_expectionchangeprovince(this.userExpection.provinceid);
      this.handle_expectionchangecity(this.userExpection.cityid);
    },
    handle_deleteexpection(row) {
      console.log("edit expection " + JSON.stringify(row));
      if (row) {
        let params = {};
        params.id = row.id;
        params.userid = row.userid;
        this.$confirm(
          "此操作将删除[" +
            row.category +
            " " +
            row.subcategory +
            "] 期望, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            USER.deleteUserExpection(params).then((res) => {
              if (res.status == 1) {
                let idx = this.userExpectionList.findIndex((item) => {
                  return item.id == row.id;
                });
                this.userExpectionList.splice(idx, 1);
              }
            });
          })
          .catch((err) => {
            console.error("Error", err);
          });
      }
    },
    handle_addexpection() {
      console.log("add expection");
      this.userExpection = {};
      this.userExpection.userid = this.cuttData.id;
      this.userExpection.typeid = 1;
      this.dlgShowExpection = true;
    },
    handle_validate3(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save3();
        } else {
          return false;
        }
      });
    },
    async handle_save3() {
      if (this.lock) {
        return;
      }
      this.lock = true;

      console.log("userexpection " + JSON.stringify(this.userExpection));
      let category = this.categoryList.filter((item) => {
        return (item.id = this.userExpection.categoryid);
      });
      this.userExpection.category = category[0].name;
      let subcategory = this.subCategoryList.filter((item) => {
        return (item.id = this.userExpection.subcategoryid);
      });
      this.userExpection.subcategory = subcategory[0].name;
      let province = this.provinces.filter((item) => {
        return item.value == this.userExpection.provinceid;
      });
      this.userExpection.province = province[0].label;
      let city = this.selectCityList.filter((item) => {
        return item.value == this.userExpection.cityid;
      });
      this.userExpection.city = city[0].label;

      let area = this.selectAreaList.filter((item) => {
        return item.value == this.userExpection.areaid;
      });
      console.log("area " + JSON.stringify(area));
      this.userExpection.area = area[0].label;
      console.log("userexpection " + JSON.stringify(this.userExpection));
      if (!this.userExpection.maxsalary) {
        this.$message.error("请输入月最高工资");
        return;
      }
      if (this.userExpection.minsalary > this.userExpection.maxsalary) {
        this.$message.error("月最低工资要小于月最高工资");
        return;
      }
      USER.saveUserExpection(this.userExpection).then((res) => {
        if (res.status == 1) {
          let data = res.data;
          let idx = this.userExpectionList.findIndex((item) => {
            return item.id == data.id;
          });
          if (idx >= 0) {
            this.userExpectionList.splice(idx, 1, data);
          } else {
            this.userExpectionList.push(data);
          }
        }
        this.res_handle(res);
        this.dlgShowExpection = false;
      });
    },

    //formatter

    formatterSalary(row, column) {
      if (row.minsalary == 0) {
        return "面议";
      } else {
        return row.minsalary + "-" + row.maxsalary + "K";
      }
    },

    //experience
    formatterExperienceDate(row, column) {
      let result = "";
      if (row.fromdate) {
        result = result + row.fromdate + "-";
      }
      if (row.todate) {
        result = result + row.todate;
      }
      return result;
    },
    handle_addexperience() {
      console.log("add experience");
      this.userExperience = {};
      this.userExperience.userid = this.cuttData.id;
      this.dlgShowExperience = true;
    },
    handle_validate4(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save4();
        } else {
          return false;
        }
      });
    },
    async handle_save4() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      this.userExperience.fromdate = moment(
        this.userExperience.fromdate
      ).format("YYYY-MM-DD");
      this.userExperience.todate = moment(this.userExperience.todate).format(
        "YYYY-MM-DD"
      );
      console.log("save experience " + JSON.stringify(this.userExperience));
      USER.saveUserExperience(this.userExperience).then((res) => {
        if (res.status == 1) {
          let data = res.data;
          let idx = this.userExperienceList.findIndex((item) => {
            return item.id == data.id;
          });
          if (idx >= 0) {
            this.userExperienceList.splice(idx, 1, data);
          } else {
            this.userExperienceList.push(data);
          }
        }
        this.res_handle(res);
        this.dlgShowExperience = false;
      });
    },
    handle_editexperience(row) {
      if (row) {
        this.userExperience = row;
      }
      this.dlgShowExperience = true;
    },
    handle_deleteexperience(row) {
      console.log("edit expection " + JSON.stringify(row));
      if (row) {
        let params = {};
        params.id = row.id;
        params.userid = row.userid;
        this.$confirm(
          "此操作将删除[" + row.position + "] 期望, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            USER.deleteUserExperience(params).then((res) => {
              if (res.status == 1) {
                let idx = this.userExperienceList.findIndex((item) => {
                  return item.id == row.id;
                });
                this.userExperienceList.splice(idx, 1);
              }
            });
          })
          .catch((err) => {
            console.error("Error", err);
          });
      }
    },
    //education
    handle_addeducation() {
      console.log("add education");
      this.userEducation = {};
      this.userEducation.userid = this.cuttData.id;
      this.dlgShowEducation = true;
    },
    handle_validate5(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save5();
        } else {
          return false;
        }
      });
    },
    async handle_save5() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      this.userEducation.fromdate = moment(this.userEducation.fromdate).format(
        "YYYY-MM-DD"
      );
      this.userEducation.todate = moment(this.userEducation.todate).format(
        "YYYY-MM-DD"
      );
      console.log("save education " + JSON.stringify(this.userEducation));
      USER.saveUserEducation(this.userEducation).then((res) => {
        if (res.status == 1) {
          let data = res.data;
          let idx = this.userEducationList.findIndex((item) => {
            return item.id == data.id;
          });
          if (idx >= 0) {
            this.userEducationList.splice(idx, 1, data);
          } else {
            this.userEducationList.push(data);
          }
          this.res_handle(res);
          this.dlgShowEducation = false;
        }
      });
    },
    handle_editeeducation(row) {
      if (row) {
        this.userEducation = row;
      }
      this.dlgShowEducation = true;
    },
    handle_deleteeducation(row) {
      console.log("edit education " + JSON.stringify(row));
      if (row) {
        let params = {};
        params.id = row.id;
        params.userid = row.userid;
        this.$confirm(
          "此操作将删除[" +
            row.schoolname +
            " " +
            row.major +
            "] 学历, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            USER.deleteUserEducation(params).then((res) => {
              if (res.status == 1) {
                let idx = this.userEducationList.findIndex((item) => {
                  return item.id == row.id;
                });
                this.userEducationList.splice(idx, 1);
              }
            });
          })
          .catch((err) => {
            console.error("Error", err);
          });
      }
    },

    //certification
    handle_addcertification() {
      console.log("add certification");
      this.userCertification = {};
      this.userCertification.userid = this.cuttData.id;
      this.dlgShowCertification = true;
    },
    handle_validate6(ref) {
      console.log("certifications " + JSON.stringify(this.userCertification));
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save6();
        } else {
          return false;
        }
      });
    },
    async handle_save6() {
      console.log("save 6 " + JSON.stringify(this.userCertification));
      if (this.lock) {
        return;
      }
      if (!this.userCertification.name) {
        this.$message.error("请输入证书名称");
        return;
      }

      // if (!this.userCertification.uploadfileid) {
      //   this.$message.error("请选择上传图片");
      //   return;
      // }
      this.lock = true;
      this.userCertification.userid = this.cuttData.id;
      USER.saveUserCertification(this.userCertification).then((res) => {
        if (res.status == 1) {
          let data = res.data;
          let idx = this.userCertificationList.findIndex((item) => {
            return item.id == data.id;
          });
          if (idx >= 0) {
            this.userCertificationList.splice(idx, 1, data);
          } else {
            this.userCertificationList.push(data);
          }
          this.res_handle(res);
          this.dlgShowCertification = false;
        }
      });
    },
    handle_deletecertification(row) {
      console.log("edit certification " + JSON.stringify(row));
      if (row) {
        let params = {};
        params.id = row.id;
        params.userid = row.userid;
        this.$confirm(
          "此操作将删除[" + row.name + "] 证书, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            USER.deleteUserCertification(params).then((res) => {
              if (res.status == 1) {
                let idx = this.userCertificationList.findIndex((item) => {
                  return item.id == row.id;
                });
                this.userCertificationList.splice(idx, 1);
              }
            });
          })
          .catch((err) => {
            console.error("Error", err);
          });
      }
    },
    successUploadImage: function (res) {
      console.log("upload img " + JSON.stringify(res));
      if (res.status == 1) {
        this.userCertification.uploadfileid = res.data.id;
      }
    },
  },
};
</script>
<style scoped>
.demo-ruleForm {
  margin-top: 10px;
}

.tabs-box {
  width: 1000px;
  margin: 20px auto;
}
.block-box {
  width: 100%;
  margin-bottom: 20px;
  float: left;
}
label.label {
  display: inline-block;
  text-align: right;
  font-weight: bold;
  font-size: 14px;
  color: #606266;
  width: 200px;
  margin-right: 15px;
}
.row span {
  font-size: 14px;
  color: #606266;
  margin-right: 15px;
}
.buttons {
  line-height: 50px;
  text-align: center;
}
.dialog-box {
  margin: 0 0 20px 20px;
}
.dispatch-box {
  margin-bottom: 10px;
}
.dispatch-box div {
  line-height: 50px;
}
</style>